<template>
    <v-card class="mx-auto padding-10 mtb" outlined>
        <div>
            <div class="action-buttons">
                <v-btn
                    class="ma-2"
                    color="error"
                    @click="deleteClick"
                >
                    <v-icon
                        left
                        dark
                    >
                        mdi-minus-circle
                    </v-icon>
                    Delete Stack
                </v-btn>
            </div>
            <v-select v-model="currentStackType" 
                        :items="stackTypeOptions"
                        item-value="id"
                        item-text="display"
                        class="stack-type"
                        @change="clearStacked"> 
            </v-select>
            <div v-if="localStack.stackType === 0" style="display: inline-block;">
                the following player(s) together in a lineup between 
                <v-text-field
                    type="number"
                    class="inline sm"
                    v-model="localStack.minExposure"
                    @change="stackChanged"
                ></v-text-field>
                and 
                <v-text-field
                    type="number"
                    class="inline sm"
                    v-model="localStack.maxExposure"
                    @change="stackChanged"
                ></v-text-field> 
                percent of the time.
            </div>
            <div v-if="localStack.stackType === 1" style="display: inline-block;">
                the player 
                <v-autocomplete
                    v-model="localStack.driverProjectionId"
                    :items="projectionsDisplay"
                    item-text="name"
                    item-value="id"
                    :filter="playerStackFilter"
                    :search-input.sync="localStack.driverInput"
                    @change="stackChanged"
                    class="inline"
                ></v-autocomplete>
                is in the lineup, then use between 
                <v-text-field
                    type="number"
                    class="inline sm"
                    v-model="localStack.minStacked"
                    @change="stackChanged"
                ></v-text-field>
                and
                <v-text-field
                    type="number"
                    class="inline sm"
                    v-model="localStack.maxStacked"
                    @change="stackChanged"
                ></v-text-field>
                of the following players in the same lineup.
            </div>
        </div>
        <div>
            <div>
                Players to stack: 
                <v-autocomplete
                    v-model="localStack.stackWithSelection"
                    :items="stackWithOptions"
                    dense
                    filled
                    label="Player"
                    item-text="name"
                    item-value="id"
                    :filter="playerStackFilter"
                    :search-input.sync="localStack.stackWithInput"
                    class="inline"
                    @change="stackWithSelected"
                ></v-autocomplete> 
            </div>
            <div>
                <div v-for="proj in stacksDisplay" :key="proj.id">
                    {{ projectionName(proj) }}
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "StackView",
    props: {
        stack: {
            type: Object,
            required: true
        },
        projections: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            localStack: {...this.stack},
            stackTypeOptions: [
                {
                    id: 0,
                    display: 'Use'
                },
                {
                    id: 1,
                    display: 'If'
                }
            ]
        };
    },
    computed: {
        currentStackType: {
            get() {
                return this.stackTypeOptions.find(o => o.id === this.localStack.stackType);
            },
            set(newValue) {
                this.localStack.stackType = newValue;
            }
        },
        // projections to display
        projectionsDisplay: function() {
            return this.projections.map(p => {
                return {
                    name: this.projectionName(p),
                    player: p.player,
                    team: p.team,
                    id: p.id
                };
            });
        },
        // stack with options for this stack
        stackWithOptions: function() {
            var available = [...this.projections];
            if (this.localStack.stackedProjectionIds)
                available = available.filter(p => !this.localStack.stackedProjectionIds.includes(p.id));
                
            return available.map(p => {
                return {
                    name: this.projectionName(p),
                    player: p.player,
                    team: p.team,
                    id: p.id
                };
            });
        },
        stacksDisplay: function() {
            if (!this.localStack || !this.localStack.stackedProjectionIds || this.localStack.stackedProjectionIds.length === 0)
                return [];
            return this.localStack.stackedProjectionIds.map(id => {
                return this.projections.find(p => p.id === id);
            });
        }
    },
    methods: {
        clearStacked: function() {
            this.localStack.stackedProjectionIds = [];
            this.localStack.stackWithInput = '';
            this.localStack.stackWithSelection = '';
        },
        stackWithSelected: function() {
            this.localStack.stackedProjectionIds.push(this.localStack.stackWithSelection);
            this.stackChanged();
            this.$nextTick(() => {
                this.localStack.stackWithSelection = null;
                this.localStack.stackWithInput = '';
            });
        },
        projectionName: function(projection) {
            if (projection) {
                if (projection.salary.contestType === 'showdown')
                    return projection.player ? `${projection.salary.rosterPosition} - ${projection.player.name} (${projection.team.abbreviation})` : `${projection.salary.rosterPosition} - ${projection.team.fullName}`;
                return projection.player ? `${projection.player.name} (${projection.team.abbreviation})` : projection.team.fullName;
            }
            return null;
        },
        playerStackFilter: function(item, queryText) {
            const playerName = item.player ? item.player.name.toLowerCase() : null;
            const teamName = item.team.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return ((playerName && playerName.indexOf(searchText) > -1) || (teamName && teamName.indexOf(searchText) > -1));
        },
        stackChanged: function() {
            this.$emit('stack-changed', this.localStack);
        },
        deleteClick: function() {
            this.$emit('delete-stack', this.localStack);
        }
    }
};
</script>
<style scoped>
.top-margin {
  margin-top: 20px;
}
.padding-10 {
    padding: 10px;
}
.mtb {
    margin-top: 6px;
    margin-bottom: 6px;
}
div.inline {
    display: inline-block;
    width: 300px;
}
div.sm {
    width: 100px;
}
div.action-buttons {
    float: right;
    width: 400px;
    text-align: right;
}
div.stack-type {
    width: 60px;
    display: inline-block;
}
div.driver-select {
    width: 300px;
    display: inline-block;
}
</style>