<template>
    <v-container fluid>
      <div v-if="!userHasAccessRole">
        <NoAccess />
      </div>
      <div v-else>
        <v-row class="bt-msg">
            <v-alert
                border="top"
                color="red lighten-2"
                dark
                class="bt-alert">
                This is the backtesting utility. This page is not intended to be used to create lineups for contests. You will not be able to export lineups from this page.
            </v-alert>
        </v-row>
        <v-row>
          <v-col cols="2">
            <ContestProviderSelector @change="contestProviderChange"
              :providers="providers.data"
              :selected-provider="providers.selected"
              :disabled="loadingProjections" />
          </v-col>
          <v-col cols="2">
            <YearSelector @change="yearChange"
              :selected-year="selectedYear"
              :current-year="currentYear"
              :disabled="loadingProjections" />
          </v-col>
          <v-col cols="2">
            <WeekSelector @change="setProjectionsTab" 
                :backtesting="true"
                :disabled="loadingProjections" />
          </v-col>
          <v-col cols="2">
            <SlateSelector @change="slateChange"
                :slates="slates.data"
                :selectedSlate="slates.selected"
                :disabled="loadingProjections" />
          </v-col>
          <v-col cols="4" class="proj-upd">
          </v-col>
        </v-row>
        <div class="odds-container" v-if="!loadingOdds && hasOdds">
          <GameOdds v-for="(odd, idx) in odds" :key="idx" :odd="odd" />
        </div>
        <v-row v-if="showProjections">
          <v-col cols="12">
            <v-tabs v-model="tabModel">
              <v-tab href="#tab-proj"> Projections </v-tab>
              <v-tab href="#tab-rules" :disabled="loadingProjections"> Group Rules </v-tab>
              <v-tab href="#tab-stacks" :disabled="loadingProjections"> Stacks </v-tab>
              <v-tab href="#tab-settings" :disabled="loadingProjections"> Build Settings </v-tab>
              <v-tab href="#tab-build" :disabled="loadingProjections"> Builds </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabModel">
              <v-tab-item value="tab-proj">
                <Projections :backtesting="true" />
              </v-tab-item>
              <v-tab-item value="tab-rules">
                <FootballGroupRules />
              </v-tab-item>
              <v-tab-item value="tab-stacks">
                <FootballStacksBuilder />
              </v-tab-item>
              <v-tab-item value="tab-settings">
                <FootballBuildSettings />
              </v-tab-item>
              <v-tab-item value="tab-build">
                <BuildLineups :backtesting="true" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </template>
  
  <script>
  import BuildLineups from "./BuildLineups";
  import FootballBuildSettings from "@/components/football/FootballBuildSettings";
  import ContestProviderSelector from "@/components/shared/ContestProviderSelector";
  import GameOdds from "@/components/football/GameOdds";
  import Projections from "@/components/football/Projections";
  import SlateSelector from "@/components/shared/SlateSelector";
  import FootballStacksBuilder from "@/components/football/FootballStacksBuilder";
  import FootballGroupRules from "@/components/football/FootballGroupRules";
  import WeekSelector from "@/components/football/NflWeekSelector";
  import YearSelector from "@/components/shared/YearSelector";
  import NoAccess from "@/components/NoAccess";
  
  import { createNamespacedHelpers } from 'vuex';
  const { mapActions, mapState } = createNamespacedHelpers('football');
  
  export default {
    name: "Backtesting",
    components: {
      BuildLineups,
      FootballBuildSettings,
      ContestProviderSelector,
      GameOdds,
      Projections,
      SlateSelector,
      FootballStacksBuilder,
      FootballGroupRules,
      WeekSelector,
      YearSelector,
      NoAccess
    },
    data: function () {
      return {
        tabModel: "tab-proj",
      };
    },
    created: function () {
      this.loadProviders();
    },
    mounted: async function() {
      this.loadCurrentValues();
      this.loadTeams();
    },
    computed: {
      userHasAccessRole() {
        return this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('admin');
      },
      showProjections() {
        return this.loadedProjections || this.loadingProjections;
      },
      hasOdds() {
        return this.odds && this.odds.length > 0;
      },
      ...mapState({
          projections: state => state.projections.data,
          loadedProjections: state => state.projections.loaded,
          loadingProjections: state => state.projections.loading,
          odds: state => state.odds.data,
          loadingOdds: state => state.odds.loading,
          season: state => state.selectedYear,
          week: state => state.selectedWeek,
          settings: state => state.settings,
          providers: state => state.providers,
          currentYear: state => state.currentYear,
          selectedYear: state => state.selectedYear,
          slates: state => state.slates,
      })
    },
    methods: {
      contestProviderChange(value) {
        this.changeProvider(value);
        this.setProjectionsTab();
      },
      yearChange(value) {
        this.changeYear(value);
        this.setProjectionsTab();
      },
      slateChange(value) {
        this.changeSlate(value);
        this.setProjectionsTab();
      },
      setProjectionsTab() {
        this.tabModel = "tab-proj";
      },
      ...mapActions([
        'loadTeams',
        'loadCurrentValues',
        'loadProviders',
        'changeProvider',
        'changeYear',
        'changeSlate',
      ])
    }
  };
  </script>
  <style scoped>
  @media (min-width: 1904px) {
    div.container {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    div.odds-container {
      margin: 10px 0;
      width: 100%;
      height: auto;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-bottom: 10px;
    }
    .proj-upd {
      text-align: right;
      padding: 20px;
      font-size: 14px;
    }
    div.bt-msg {
        text-align: center;
        margin: auto;
    }
    div.bt-alert {
        margin: auto;
        width: 100%;
    }
  }
  </style>